import type { SVGProps } from "react";
const IconStarRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7497 11.41C21.3397 10.84 21.0096 9.83002 20.1896 9.71002L15.7096 9.06C15.3796 9.01 15.0996 8.81001 14.9496 8.51001L13.0097 4.59998C12.8197 4.19998 12.4297 4 12.0497 4C11.6697 4 11.2796 4.19998 11.0896 4.59998L9.14967 8.51001C8.99967 8.81001 8.71966 9.01 8.38966 9.06L3.90969 9.71002C3.08969 9.83002 2.75963 10.84 3.34963 11.41L6.59962 14.56C6.82962 14.79 6.93965 15.12 6.87965 15.44L6.13967 19.75C6.01967 20.44 6.5696 21 7.1996 21C7.3596 21 7.5296 20.96 7.6996 20.88L11.5796 18.84C11.7296 18.76 11.8897 18.73 12.0497 18.73C12.2097 18.73 12.3697 18.76 12.5197 18.84L16.3997 20.88C16.5697 20.96 16.7397 21 16.8997 21C17.5297 21 18.0796 20.44 17.9596 19.75L17.2196 15.44C17.1596 15.12 17.2697 14.79 17.4997 14.56L20.7497 11.41ZM8.35988 15.7003C8.49988 14.8903 8.22991 14.0602 7.63991 13.4802L5.12988 11.0502L8.60988 10.5402C9.41988 10.4302 10.1199 9.92024 10.4899 9.18024L12.0498 6.03027L13.6098 9.18024C13.9798 9.92024 14.6798 10.4302 15.4898 10.5402L18.9698 11.0502L16.4597 13.4802C15.8697 14.0602 15.5998 14.8903 15.7398 15.7003L16.3297 19.1403L13.2097 17.5103C12.8497 17.3203 12.4498 17.2302 12.0498 17.2302C11.6498 17.2302 11.2499 17.3203 10.8899 17.5103L7.76991 19.1403L8.35988 15.7003Z"
      fill="currentColor"
    />
  </svg>
);
export default IconStarRegular;
