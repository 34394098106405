import { ImagesV2 } from "@/public/images/all";
import { Checkbox } from "@headlessui/react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { ChangeEvent, useState } from "react";
import Text from "../../atoms/Text/Text";

export default function SubscriptionBox() {
  const { t } = useTranslation("stores");
  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: ""
  });

  const [agree, setAgree] = useState(false);

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D+/g, "");
    let formatted = "";

    if (cleaned.length === 0) {
      formatted = "";
    } else if (cleaned.length <= 3) {
      formatted = `(${cleaned}`;
    } else if (cleaned.length <= 6) {
      formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
      formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }

    return formatted.slice(0, 14);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedPhoneNumber = "";
    if (name === "phoneNumber") {
      formattedPhoneNumber = formatPhoneNumber(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: name === "phoneNumber" ? formattedPhoneNumber : prevFormData.phoneNumber
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // This form is not connected to any backend, will connect to backend in future
    if (agree) {
      alert(`Phone Number: ${formData.phoneNumber}, Email: ${formData.email}`);
    } else {
      alert("You must agree to the terms to submit the form.");
    }
  };

  return (
    // This form is not connected to any backend, will connect to backend in future and remove hidden class at that point
    <div className="hidden px-4 my-7 max-w-8xl mx-auto">
      <div className="rounded-[10px] bg-primary p-4 lg:p-5 xl:p-10 xl:flex justify-between">
        <div className="xl:max-w-[800px]">
          <Text variant="title1" className="lg:text-4xl">
            {t("be_first_know")}
          </Text>
          <Text variant="body2" className="mt-1 lg:text-base">
            {t("subscription_text")}
          </Text>

          <form onSubmit={handleSubmit}>
            <div className="flex gap-4 mt-10 mb-5">
              <input
                type="text"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                name="phoneNumber"
                onChange={(e) => handleChange(e)}
                className="input rounded-lg border-none w-2/5"
                pattern="^\(\d{3}\) \d{3}-\d{4}$"
                title="Phone number must be in the format: (123) 456-7890"
              />
              <input
                type="email"
                placeholder="Email (Optional)"
                value={formData.email}
                name="email"
                onChange={(e) => handleChange(e)}
                className="rounded-lg border-none w-2/5"
              />
              <button type="submit" className="button w-1/5">
                Sign Up
              </button>
            </div>
            <div className="flex" onClick={() => setAgree((prev) => !prev)}>
              <Checkbox
                checked={agree}
                className="group block w-8 h-4 rounded-sm border-black border bg-primary data-[checked]:bg-black data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 mr-3 mt-0.5"
              >
                <svg
                  className="stroke-primary opacity-0 group-data-[checked]:opacity-100 p-0.5"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path d="M3 8L6 11L11 5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Checkbox>
              <label className="text-sm">
                By checking this box I agree to receiving future marketing communications regarding Crumbl Cookies
                promotions from donotreply_marketing@crumbl.com. I understand that I am able to unsubscribe from these
                communications at any time using the unsubscribe link.
              </label>
            </div>
          </form>
        </div>

        <div className="hidden xl:block xl:visible">
          <Image src={ImagesV2.cookieOverhead} alt="" width={406} height={406} />
        </div>
      </div>
    </div>
  );
}
