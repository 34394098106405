import { ImagesV2 } from "@/public/images/all";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Text from "../../atoms/Text/Text";
import { DownloadTheAppButtons } from "../DownloadTheApp/DownloadTheAppButtons";

export default function FreeCookieDay({ store }) {
  const { t } = useTranslation("stores");

  const freeCookieDate = dayjs(store?.freeCookieDayDate);

  return (
    <div className="px-4 my-7 max-w-8xl mx-auto">
      <div className="rounded-[10px] bg-primary xl:flex justify-between">
        <div className="xl:max-w-[800px]">
          <div className="p-4 lg:p-5 xl:p-10 space-y-1">
            <Text variant="lineItem" className="lg:text-base">
              {freeCookieDate.format("dddd, MMMM D")}
            </Text>
            <Text variant="display3" className="lg:text-5xl">
              {t("free_cookie_day")}
            </Text>
            <Text variant="body2" className="lg:text-base">
              {t("free_cookie_day_text", {
                storeName: store.name,
                date: freeCookieDate.format("MMMM D")
              })}
            </Text>
          </div>

          <Image src={ImagesV2.freeCookieDay} alt="" className="lg:hidden" />

          <div className="p-4 lg:p-5 xl:p-10 !pt-0">
            <DownloadTheAppButtons />
          </div>
        </div>

        <div className="hidden xl:block xl:visible -my-16">
          <Image src={ImagesV2.cookieOverheadAerial} alt="" width={406} height={406} />
        </div>
      </div>
    </div>
  );
}
