import { ImagesV2 } from "@/public/images/all";
import { AppStoreURL, PlayStoreUrl, attemptToOpenCrumblApp, isAndroid, isIOS } from "@/static/lib/util";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { ReactNode } from "react";
import Text from "../../atoms/Text/Text";
import Button from "../Button/Button";

interface Props {
  children?: ReactNode;
}

export const DownloadTheAppButtons = ({ children }: Props) => {
  const { t } = useTranslation("order");
  const handleClick = () => {
    attemptToOpenCrumblApp();
  };

  if (isAndroid() || isIOS()) {
    return <Button onClick={handleClick}>{children || <Text>{t("download_the_app")}</Text>}</Button>;
  }

  return (
    <div className="flex gap-2">
      <Link href={PlayStoreUrl}>
        <Image src={ImagesV2.playStoreBadge} alt="Play Store button" width={168} height={50} />
      </Link>

      <Link href={AppStoreURL}>
        <Image src={ImagesV2.downloadAppStore} alt="App Store button" width={154} height={50} />
      </Link>
    </div>
  );
};
