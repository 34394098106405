import { calculateDistanceFromStore } from "@/static/lib/old-order-shipping";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useEffect } from "react";
import IconStoreFilled from "../../atoms/Icons/StoreFilled";
import Text from "../../atoms/Text/Text";
import { useGeolocation } from "../../contexts/GeolocationContext/GeolocationContext";

export default function NearbyLocations({ locations }) {
  const { t } = useTranslation("stores");
  const { geolocation, fetchGeolocation, loading: geoLocationLoading } = useGeolocation();

  useEffect(() => {
    if (!geolocation && !geoLocationLoading) {
      fetchGeolocation();
    }
  }, []);

  const formatDistance = (distance) => {
    if (!distance) {
      return null;
    }

    if (distance > 1000) {
      return t("stores:num_thousand_miles_away", { num: (distance / 1000).toFixed(1) });
    }

    return t(`stores:num_miles_away`, { num: distance.toFixed(1) });
  };

  return (
    <div className="px-4 space-y-4 my-7 max-w-8xl mx-auto xl:my-24">
      <Text variant="display3" className="text-center lg:text-4xl">
        {t("nearby_locations")}
      </Text>
      <div className="flex flex-col md:flex-row flex-wrap justify-center gap-5">
        {locations?.map((l) => {
          const numMilesAway = calculateDistanceFromStore(l, geolocation as any);
          return (
            <Link key={l.storeId} href={`/${l.slug}`} className="sm:min-w-96 hover:bg-grey-10 rounded-[10px]">
              <div className="flex space-x-4 rounded-[10px] border border-solid border-black/20 p-4">
                <IconStoreFilled className="text-primary w-9 h-9" />
                <div>
                  <Text variant="title3">{l.name}</Text>
                  <Text variant="body2">
                    {l.street}
                    <br />
                    {[l.city, l.state, l.zip].join(", ")}
                  </Text>
                  {geolocation && numMilesAway && (
                    <Text variant="caption" className="text-black/40 mt-2.5">
                      {formatDistance(numMilesAway)}
                    </Text>
                  )}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
