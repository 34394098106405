import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Text from "../../atoms/Text/Text";

export default function StoreMenu({ storeName, currentCookieWeek, nationalFlavors, hometownPicks }) {
  const { t } = useTranslation("stores");

  // cookie week starts/ends on Sunday, but we want to show Mon - Sat on the UI
  const startOfWeek = dayjs(currentCookieWeek?.startDate).add(1, "day");
  const endOfWeek = dayjs(currentCookieWeek?.endDate).subtract(1, "day");
  const week = `${startOfWeek.format("MMM D")} - ${
    endOfWeek.isSame(startOfWeek, "month") ? endOfWeek.format("D") : endOfWeek.format("MMM D")
  }`;

  if (!nationalFlavors?.length && !hometownPicks?.length) {
    return null;
  }

  return (
    <div className="my-7 lg:my-28 px-4 text-center max-w-8xl mx-auto">
      <Text variant="body2" className="bg-primary rounded px-4 py-1.5 inline-block lg:text-base">
        {t("week_of", { week })}
      </Text>

      {/* national flavors */}
      {nationalFlavors?.length > 0 && (
        <div>
          <div className="my-2.5">
            <Text variant="display3" className="lg:text-5xl">
              {t("national_flavors")}
            </Text>
            <Text variant="body2" className="lg:text-base max-w-[900px] mx-auto">
              {t("national_flavors_text", { storeName })}
            </Text>
          </div>

          <div className="flex flex-wrap justify-center gap-7 my-10 max-w-[740px] mx-auto xl:max-w-[1402px] xl:gap-[50px]">
            {nationalFlavors.map((c) => (
              <div key={c.id} className="flex flex-col items-center space-y-1 w-[130px]  xl:w-[313px] ">
                <div className="h-[130px] xl:h-[313px]">
                  {c.aerialImage && <Image src={c.aerialImage} width={313} height={313} alt={c.name} />}
                </div>

                <Text variant="smallHeader" className="xl:text-lg">
                  {c.name}
                </Text>
                {c?.calorieInformation?.total && (
                  <Text variant="finePrint" className="text-black/60 xl:hidden">
                    {t("num_cal", { num: c.calorieInformation.total })}
                  </Text>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* hometown picks */}
      {hometownPicks?.length > 0 && (
        <div className="lg:pt-24">
          <div className="my-2.5">
            <Text variant="display3" className="lg:text-5xl">
              {t("hometown_picks")}
            </Text>
            <Text variant="body2" className="lg:text-base max-w-[900px] mx-auto">
              {t("hometown_picks_text", { storeName })}
            </Text>
          </div>

          <div className="flex flex-wrap justify-center gap-7 my-10 max-w-[740px] mx-auto xl:max-w-[1402px] xl:gap-[50px]">
            {hometownPicks.map(({ cookie: c }) => (
              <div key={c.id} className="flex flex-col items-center space-y-1 w-[130px]  xl:w-[313px] ">
                <div className="h-[130px] xl:h-[313px]">
                  {c.aerialImage && <Image src={c.aerialImage} width={313} height={313} alt={c.name} />}
                </div>

                <Text variant="smallHeader" className="xl:text-lg">
                  {c.name}
                </Text>
                {/*c.calorieInformation.total && (
               <Text variant="finePrint" className="text-black/60 xl:hidden">
                 {t("num_cal", { num: c.calorieInformation.total })}
               </Text>
             )*/}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
