import classNames from "classnames";
import { CSSProperties } from "react";
import IconStarFilled from "../../atoms/Icons/StarFilled";
import IconStarHalfFilled from "../../atoms/Icons/StarHalfFilled";
import IconStarQuarterFilled from "../../atoms/Icons/StarQuarterFilled";
import IconStarRegular from "../../atoms/Icons/StarRegular";
import IconStarThreeQuarterFilled from "../../atoms/Icons/StarThreeQuarterFilled";

interface StarRatingProps {
  className?: string;
  style?: CSSProperties;
  rating: number;
}

export default function StarRating({ className, rating, style }: StarRatingProps) {
  const totalStars = 5;
  const roundedRating = Math.round(rating * 4) / 4;
  const filledStars = Math.floor(roundedRating);
  const remaining = roundedRating - filledStars;

  const stars = [];

  // Push filled stars
  for (let i = 0; i < filledStars; i++) {
    stars.push(
      <IconStarFilled
        style={style}
        key={i}
        className="w-12 h-12"
        {...(process.env.NODE_ENV === "test" ? { "data-testid": "filled-star" } : {})}
      />
    );
  }

  // Push partially filled stars
  if (remaining >= 0.75) {
    stars.push(
      <IconStarThreeQuarterFilled
        style={style}
        key={filledStars}
        className="w-12 h-12"
        {...(process.env.NODE_ENV === "test" ? { "data-testid": "partially-filled-star" } : {})}
      />
    );
  } else if (remaining >= 0.5) {
    stars.push(
      <IconStarHalfFilled
        style={style}
        key={filledStars}
        className="w-12 h-12"
        {...(process.env.NODE_ENV === "test" ? { "data-testid": "partially-filled-star" } : {})}
      />
    );
  } else if (remaining >= 0.25) {
    stars.push(
      <IconStarQuarterFilled
        style={style}
        key={filledStars}
        className="w-12 h-12"
        {...(process.env.NODE_ENV === "test" ? { "data-testid": "partially-filled-star" } : {})}
      />
    );
  }

  // Push unfilled stars to complete the total
  for (let i = stars.length; i < totalStars; i++) {
    stars.push(
      <IconStarRegular
        style={style}
        key={i}
        className="w-12 h-12"
        {...(process.env.NODE_ENV === "test" ? { "data-testid": "unfilled-star" } : {})}
      />
    );
  }

  return <div className={classNames("inline-flex items-center", className)}>{stars}</div>;
}
