// DEPRECATED: please see `lib/states-and-territories.ts` for handling
// state names and abbreviations.

export const states = [
  "Alabama|AL|32.879499,-86.683490",
  "Alaska|AK|64.825976,-150.889511",
  "Arizona|AZ|34.344305,-111.734740",
  "Arkansas|AR|34.703120,-92.209212",
  "California|CA|36.533922,-119.692990",
  "Colorado|CO|39.316583,-105.683422",
  "Connecticut|CT|41.609076,-72.808802",
  "Delaware|DE|39.199947|-75.556425",
  "District of Columbia|DC|38.907917,-77.036871",
  "Florida|FL|27.804498,-81.843513",
  "Georgia|GA|32.595273,-83.378201",
  "Hawaii|HI|20.543145,-157.393217",
  "Idaho|ID|43.533088,-114.062572",
  "Illinois|IL|40.199271,-89.405062",
  "Indiana|IN|40.225784,-86.180404",
  "Iowa|IA|42.020233,-93.540440",
  "Kansas|KS|38.509055,-98.396109",
  "Kentucky|KY|37.413316, -84.955014",
  "Louisiana|LA|30.734415,-91.757323",
  "Maine|ME|45.024393,-69.198802",
  "Maryland|MD|39.498520,-77.179910",
  "Massachusetts|MA|42.300124,-71.744995",
  "Michigan|MI|43.855910,-84.925039",
  "Minnesota|MN|46.215474,-94.558830",
  "Mississippi|MS|33.475487,-89.762759",
  "Missouri|MO|38.298084,-92.524680",
  "Montana|MT|46.879386,-109.591193",
  "Nebraska|NE|41.569872,-99.576718",
  "Nevada|NV|39.815464,-116.995143",
  "New Hampshire|NH|43.373144,-71.653529",
  "New Jersey|NJ|39.558192,-74.728961",
  "New Mexico|NM|34.059341,-105.602647",
  "New York|NY|42.914900,-75.873365",
  "North Carolina|NC|35.790573,-79.190048",
  "North Dakota|ND|47.423621,-100.280705",
  "Ohio|OH|40.299259,-82.802704",
  "Oklahoma|OK|35.915507,-96.987408",
  "Oregon|OR|43.765137,-120.749851",
  "Pennsylvania|PA|40.837883,-77.393287",
  "Rhode Island|RI|41.694190,-71.455385",
  "South Carolina|SC|33.971519,-80.584036",
  "South Dakota|SD|44.524138,-100.267138",
  "Tennessee|TN|35.738256,-86.596650",
  "Texas|TX|31.485406,-99.094478",
  "Utah|UT|39.050633,-111.455975",
  "Vermont|VT|43.701059,-72.772692",
  "Virginia|VA|37.335568,-78.768760",
  "Washington|WA|47.339294,-120.137010",
  "West Virginia|WV|38.419951,-81.038905",
  "Wisconsin|WI|44.682170,-89.685203",
  "Wyoming|WY|43.0760,-107.2903",
];

export const caProvincesTerritories = [
  "Alberta|AB|53.933270,-116.576500",
  "British Columbia|BC|53.726670,-127.647620",
  "Manitoba|MB|53.760860,-98.813870",
  "New Brunswick|NB|46.565316,-66.461916",
  "Newfoundland and Labrador|NL|53.135509,-57.660435",
  "Northwest Territories|NT|64.825976,-150.889511",
  "Nova Scotia|NS|44.682999,-63.744670",
  "Nunavut|NU|70.299781,-83.107567",
  "Ontario|ON|51.253775,-85.323214",
  "Prince Edward Island|PE|46.510712,-63.416813",
  "Quebec|QC|52.939915,-73.549136",
  "Saskatchewan|SK|52.939915,-106.450867",
  "Yukon|YT|64.282328,-135.000000",
];

export const usStatesProper = states.map((state) => state.split("|")[0]);
export const usStatesAbbrev = states.map((state) => state.split("|")[1]);

export const caProvincesTerritoriesProper = caProvincesTerritories.map((t) => t.split("|")[0]);
export const caProvincesTerritoriesAbbrev = caProvincesTerritories.map((t) => t.split("|")[1]);

export const calculateDistanceFromStore = (store, coordinates: Coordinates) => {
  if (!store || !coordinates) {
    return null;
  }

  const x1 = +coordinates.lng;
  const y1 = +coordinates.lat;

  const x2 = store.longitude;
  const y2 = store.latitude;
  if (isNaN(x2) || isNaN(y2) || isNaN(x1) || isNaN(y1)) {
    return null;
  }
  // 1 degree is 69 miles
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)) * 69;
};

type Coordinates = { lat: number | string; lng: number | string };
